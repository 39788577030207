import { DesignedButton, DesignedInput, Rule } from "@with-nx/simple-ui/atoms";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

import { useAuthForm } from "./useAuthForm";

export const LoginForm = ({
  shouldNotRedirectLogin,
  shouldOpenURL,
  _form,
  buttonVariant = "primary",
}: {
  shouldNotRedirectLogin?: boolean;
  shouldOpenURL?: string;
  _form?: Dispatch<SetStateAction<"login" | "register" | "forgot" | "key">>;
  buttonVariant?: string;
}) => {
  const {
    email,
    _email,
    password,
    _password,
    loading,
    error,
    login,
    _shouldNotRedirectLogin,
    _shouldOpenURL,
  } = useAuthForm();

  const [params, _params] = useState<URLSearchParams>(new URLSearchParams());

  useEffect(() => {
    const p = new URLSearchParams(window?.location?.search || "");
    _params(p);

    if (shouldNotRedirectLogin !== undefined) {
      _shouldNotRedirectLogin(shouldNotRedirectLogin);
    }

    if (shouldOpenURL) {
      _shouldOpenURL(shouldOpenURL);
    }
  }, []);

  const action = params.get("action");

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      login();
    }
  };

  const handleButtonPress = () => {
    login();
  };

  return (
    <>
      <form onKeyDown={handleKeyDown}>
        <DesignedInput
          label="Email"
          type="email"
          value={email}
          change={(email) => _email(email)}
          native={{
            name: "email",
            cypress: "field-email",
          }}
        />

        <DesignedInput
          label="Password"
          type="password"
          value={password}
          change={(password) => _password(password)}
          error={error}
          bottom={0}
          native={{
            name: "password",
            cypress: "field-password",
          }}
        />
      </form>

      <Box css="d:flex a:center j:space-between" parse="mt:24">
        <Rule rule="lt" color="var(--font4)" press={() => _form("forgot")}>
          Forgot password?
        </Rule>

        <Box css="d:flex a:center">
          {!params.get("action") &&
          !params.get("redirect") &&
          !params.get("open") ? (
            <DesignedButton
              label="Sign In using Access Key"
              theme="tetriary-accent"
              size="small"
              loading={loading}
              press={() => {
                _form("key");
              }}
            />
          ) : undefined}

          <DesignedButton
            label={action || "Sign In"}
            theme={buttonVariant}
            size="small"
            loading={loading}
            press={handleButtonPress}
            properties={{
              left: 12,
              native: {
                cypress: "button-sign-in",
              },
            }}
          />
        </Box>
      </Box>
    </>
  );
};
